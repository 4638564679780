import { computed, Directive, input } from '@angular/core';
import { cn } from '@cf/core';

@Directive({
  selector: '[cfDialogButtons]',
  standalone: true,
  host: {
    '[class]': 'classes()',
  },
})
export class CfDialogButtonsDirective {
  class = input<string>();

  readonly classes = computed(() =>
    cn('tw:flex tw:flex-row tw:gap-2 tw:justify-end tw:w-full', this.class()),
  );
}
