import { computed, Directive, input } from '@angular/core';
import { cn } from '@cf/core';

@Directive({
  selector: '[cfDialogTitle]',
  standalone: true,
  host: {
    '[class]': 'classes()',
  },
})
export class CfDialogTitleDirective {
  class = input<string>();

  readonly classes = computed(() =>
    cn('tw:text-gray-80 tw:text-2xl tw:font-semibold', this.class()),
  );
}
